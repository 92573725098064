<template>
  <div :class="{ 'ui-checkbox': true, 'is-active': isActive, 'is-disabled': isDisabled }" @click="handleChange">
    <div class="circle"></div>
    <span class="label">{{ label }}</span>
  </div>
</template>

<script>
  import Vue from "vue";

  export default {
    inject: ['provider'],
    props: ['label', 'value', 'disabled'],
    computed: {
      injected() {
        return this.provider()
      },
      isActive() {
        return this.injected.value === this.value
      },
      isDisabled() {
        return this.injected.disabled || this.disabled
      }
    },
    methods: {
      handleChange(evt) {
        if (this.isDisabled) {
          return
        }
        this.injected.onChange(this.value)
      }
    },
    name: 'ui-checkbox-group-option'
  }
</script>

<style scoped>
.ui-checkbox {
  display: flex;
  align-items: center;
}

.ui-checkbox > .circle {
  margin-right: 8px;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background: var(--color-white);
  border: 1px solid var(--color-bw-2);
  cursor: pointer;
}

.ui-checkbox.is-active > .circle {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0085FF;
  color: #fff;
  border-color: transparent;
}

.ui-checkbox.is-disabled > .circle {
  background: var(--color-bw-3);
  border-color: transparent;
  cursor: not-allowed;
}

.ui-checkbox.is-disabled > .label {
  color: var(--color-bw-4);
}

.ui-checkbox.is-active > .circle::before {
  display: block;
  content: "\f00c";
  font-size: 8px;
  font-family: 'FontAwesome';
}

.ui-checkbox > .label {
  font-weight: 400;
}
</style>