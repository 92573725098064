<template>
  <div class="ui-panel">
    <div class="heading">
      {{ heading }}
    </div>
    <slot></slot>
  </div>
</template>

<script>
  import Vue from "vue";

  export default {
    props: ['heading'],
    name: 'ui-panel'
  }
</script>

<style scoped>
.ui-panel {
  padding: 16px;
  background: var(--color-white);
  border: 1px solid var(--color-bw-2);
  border-radius: var(--border-radius);
}

.ui-panel > .heading {
  padding-bottom: 16px;
  margin-bottom: 16px;
  font-size: 16px;
  border-bottom: 1px solid var(--color-bw-2);
}
</style>