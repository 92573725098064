<template>
  <button :class="{ 'ui-button': true, 'is-primary': variant === 'primary' }" v-bind="$attrs">
    <slot></slot>
  </button>
</template>

<script>
  import Vue from "vue";

  export default Vue.extend({
    props: ['variant'],
    name: 'ui-button'
  })
</script>

<style scoped>
.ui-button {
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 500;
  font-family: var(--font-family);
  color: var(--color-bw-5);
  background: transparent;
  font-weight: bold;
  border: 1px solid var(--color-bw-3);
  border-radius: var(--border-radius);
  cursor: pointer;
  -webkit-appearance: initial;
}

.ui-button:disabled {
  cursor: not-allowed;
  opacity: 0.3;
}

.ui-button.is-primary {
  color: var(--color-white);
  border-color: transparent;
  background: #0085FF;
}
</style>