<template>
  <div :class="{ 'ui-form-group': true, 'is-disabled': disabled }">
    <div class="label">
      <span class="text">{{ label }}</span>
    </div>

    <slot></slot>
  </div>
</template>

<script>
  import Vue from "vue";

  export default {
    props: ['label', 'disabled'],
    name: 'ui-form-group'
  }
</script>

<style scoped>
.ui-form-group {
  margin-bottom: 24px;
}

.ui-form-group > .label {
  position: relative;
  display: inline-block;
  margin-bottom: 16px;
}

.ui-form-group > .label > .text {
  position: relative;
  margin-bottom: 8px;
  font-weight: 600;
  text-transform: uppercase;
  z-index: 101;
}

.ui-form-group > .label::after {
  display: block;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border: 4px solid var(--color-bw-3);
  z-index: 100;
}

.ui-form-group.is-disabled > .label {
  opacity: 0.5;
}
</style>