<template>
  <div class="ui-checkbox-group">
    <slot></slot>
  </div>
</template>

<script>
  import Vue from "vue";
  import UiCheckboxGroupOption from './UiCheckboxGroupOption'

  const UiCheckboxGroup = {
    props: ['value', 'change', 'disabled'],
    provide() {
      return { provider: this.provider }
    },
    computed: {
      provider() {
        // In case you're not aware, the provider-inject feature is not reactive.
        // This workaround/pattern allows us to make the feature reactive.
        return () => {
          return {
            value: this.value,
            onChange: this.handleChange,
            disabled: this.disabled
          }
        }
      }
    },
    methods: {
      handleChange(value) {
        this.$emit('change', value)
      }
    },
    name: 'ui-checkbox-group'
  }

  export {
    UiCheckboxGroup as default,
    UiCheckboxGroupOption
  }
</script>

<style scoped>
.ui-checkbox-group > .ui-checkbox {
  margin-bottom: 8px;
}
</style>