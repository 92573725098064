<template>
  <div class="app-container">
    <div class="content">
      <ui-panel heading="Meal Plan" class="ui-panel">
        <ui-form-group
          :label="section.title"
          v-for="(section, index) in sections"
          :disabled="isSectionDisabled(index)">
          <ui-checkbox-group :value="form[index].value"
            @change="handleChange(index, $event)"
            :disabled="isSectionDisabled(index)">
            <ui-checkbox-group-option
              v-for="option in section.options"
              :value="option.id"
              :label="option.value"
              :disabled="isOptionDisabled(index, option.id)">
            </ui-checkbox-group-option>
          </ui-checkbox-group>
        </ui-form-group>
      </ui-panel>

      <footer class="footer">
        <ui-button type="button">
          Cancel
        </ui-button>

        <ui-button variant="primary" :disabled="!isSubmittable">
          Submit
        </ui-button>
      </footer>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import UiPanel from './components/UiPanel'
import UiButton from './components/UiButton'
import UiFormGroup from './components/UiFormGroup'
import UiCheckboxGroup, { UiCheckboxGroupOption } from './components/UiCheckboxGroup'
import * as data from './data'
import flatten from 'lodash.flatten'

export default {
  data() {
    return {
      sections: data.sections,
      // { value: -1 } | { value: -1 } | { value: -1 }
      form: data.sections.map(() => ({ value: -1 }))
    }
  },
  computed: {
    isSubmittable() {
      return this.currentSectionIndex >= this.form.length
    },
    currentSectionIndex() {
      const index = this.form.map(field => field.value).indexOf(-1)
      return index === -1 ? this.form.length + 1 : index;
    }
  },
  methods: {
    isSectionDisabled(index) {
      if (index === 0) {
        return false
      }
      
      return index > this.currentSectionIndex 
    },
    isOptionDisabled(index, id) {
      if (index === 0) {
        return false
      }

      const disabledOptions = flatten(
        this.form
          .slice(0, index + 1)
          .map(section => data.disables[section.value])
      )

      return disabledOptions.includes(id)
    },
    handleChange(index, value) {
      this.form.forEach((field, i) => {
        if (i === index) {
          this.form[i].value = value
        } else if (i > index) {
          // Reset all succeeding sections
          this.form[i].value = -1
        }
      })
    }
  },
  components: {
    UiPanel,
    UiButton,
    UiFormGroup,
    UiCheckboxGroup,
    UiCheckboxGroupOption
  }
};
</script>

<style>
html, body {
  --color-white: #fff;
  --color-bw-1: #F0F4F8;
  --color-bw-2: #D9E2EC;
  --color-bw-3: #BCCCDC;
  --color-bw-4: #9FB3C8;
  --color-bw-5: #829AB1;
  --color-bw-6: #627D98;
  --color-bw-7: #486581;
  --color-bw-8: #334E68;
  --color-bw-9: #243B53;
  --color-bw-10: #102A43;
  --font-family: 'halyard-display', sans-serif;
  --font-size: 14px;
  --form-size: 40px;
  --border-radius: 4px;

  color: var(--color-bw-10);
  font-size: var(--font-size);
  font-family: var(--font-family);
}

.app-container {
  padding-top: 80px;
  padding-bottom: 80px;
  background: var(--color-bw-1);
  min-height: 100vh;
  width: 100%;
}

.app-container > .content {
  width: 480px;
  margin: 0 auto;
  animation-name: fade-in-up;
  animation-duration: 1s;
}

@keyframes fade-in-up {
  0% {
    transform: translatey(24px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.app-container > .content > .footer {
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
}
</style>